<script>
import { unpublishNews } from '@/api/news'
import { mdiPublishOff } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    news: {
      type: Object,
    }
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)

    const loading = ref(false)

    const closeDialog = () => {
      isDialogVisible.value = false
    }

    const handleSubmit = async () => {
      loading.value = true

      try {
        const { status } = await unpublishNews(props.news.id)
        if (status === 200) {
          emit('unpublished')

          closeDialog()
        }
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      isDialogVisible,
      loading,
      closeDialog,
      handleSubmit,

      icons: {
        mdiPublishOff
      },
    }
  },
}
</script>

<template>
  <div>
    <v-dialog v-model="isDialogVisible" max-width="600px" height="400px">
      <!-- Dialog Activator -->
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-icon size="17" class="me-1">
            {{ icons.mdiPublishOff }}
          </v-icon>
          <span>Unpublish</span>
        </div>
      </template>

      <!-- Dialog Content -->
      <v-card title="Import Doctor List">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p>Title: {{ news.title.en }}</p>
              <p>All associated notifications will be deleted. Are you sure you want to unpublish this news?</p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error" outlined @click="closeDialog" :disabled="loading"> Close </v-btn>
          <v-btn color="success" @click="handleSubmit" :disabled="loading" :loading="loading"> Unpublish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
