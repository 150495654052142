var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('translation-locales'), _c('v-card', [_c('v-card-text', [_c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "items": _vm.typeOptions,
      "outlined": "",
      "label": "Type",
      "placeholder": "Select Type"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Title",
      "outlined": ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }), _c('translatable-editor', {
    staticClass: "mt-5",
    attrs: {
      "label": "Content",
      "outlined": "",
      "eidtor-options": {
        uploadUrl: 'news',
        postUuid: _vm.form.post_uuid
      }
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "items": _vm.statusOptions,
      "outlined": "",
      "label": "Status",
      "placeholder": "Select Status"
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }), _c('v-combobox', {
    attrs: {
      "items": _vm.distributorOptions,
      "item-value": "value",
      "item-text": "title",
      "multiple": "",
      "chips": "",
      "label": "Distributors",
      "outlined": "",
      "hint": "Leave it blank if you want to publish to users in all distributors.",
      "persistent-hint": ""
    },
    model: {
      value: _vm.form.distributor_ids,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_ids", $$v);
      },
      expression: "form.distributor_ids"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Send in-app notification",
      "hint": "Ensure that the news content is brief and contains text only for in-app notifications.",
      "persistent-hint": ""
    },
    model: {
      value: _vm.form.in_app_notification,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "in_app_notification", $$v);
      },
      expression: "form.in_app_notification"
    }
  }), _c('p', {
    staticClass: "mt-5"
  }, [_vm._v(" Publish Datetime ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "col": "6"
    }
  }, [_c('v-date-picker', {
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1), _c('v-col', {
    attrs: {
      "col": "6"
    }
  }, [_c('v-time-picker', {
    attrs: {
      "color": "primary",
      "ampm-in-title": "",
      "allowed-minutes": [0, 15, 30, 45]
    },
    model: {
      value: _vm.form.time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "time", $$v);
      },
      expression: "form.time"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }