<template>
  <div>
    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn icon @click="showFilter = !showFilter">
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-show="showFilter" class="px-2 ma-0">
          <v-col cols="4">
            <v-select v-model="filters.status" placeholder="Status" :items="statusOptions"
              item-text="title" item-value="value" single-line dense outlined clearable
              hide-details></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-row class="my-0 d-flex justify-end">
      <v-col cols="12" sm="2" class="mr-auto">
        <v-btn color="primary" block class="" :loading="loading" :disabled="loading"
          @click="loadNews">
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn depressed block color="success" @click="setAside(null)">
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          Create
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table v-model="tableSelectedData" :headers="tableColumns" :items="tableList"
        :options.sync="options" :server-items-length="tableTotal" :loading="loading"
        class="table-section text-no-wrap" :header-props="headerprops">
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <template #[`item.status`]="{ item }">
          {{ item.status }}
        </template>

        <template #[`item.title`]="{ item }">
          <router-link class="text-decoration-none"
            :to="{ name: 'news-view', params: { id: item.id }}">
            {{ t(item.title) }}
          </router-link>
        </template>

        <template #[`item.type`]="{ item }">
          {{ item.type }}
        </template>

        <!-- date -->
        <template #[`item.published_at`]="{ item }">
          <span class="text-no-wrap">{{ formatDate(item.published_at) }}</span>
        </template>

        <template #[`item.created_at`]="{ item }">
          <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="setAside(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>{{ $t('common.list.edit') }}</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="item.status === 'published'">
                  <v-list-item-title>
                    <UnpublishDialog :news="item" @unpublished="loadNews" />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <news-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadNews()
        aside = false
      "
    />
  </div>
</template>

<script>
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import NewsAside from './news-resource/NewsAside.vue'
import UnpublishDialog from './news-resource/UnpublishDialog.vue'
import useNewsList from './useNewsList'

export default {
  components: { NewsAside, UnpublishDialog },

  setup() {
    const {
      tableList,
      tableColumns,
      tableTotal,
      tableSelectedData,

      statusOptions,
      options,
      filters,
      search,

      loadNews,
      deleteNews,

      loading,
    } = useNewsList()

    const actions = ['Delete', 'Edit']

    const selectedAction = ref('')

    const aside = ref(false)
    const resource = ref(null)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      setAside,
      aside,
      resource,

      statusOptions,
      options,
      filters,
      search,

      loading,
      actions,
      selectedAction,
      loadNews,
      deleteNews,

      t,
      formatDate,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
      },

      headerprops,
      showFilter,
    }
  },
}
</script>
