<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-select
          v-model="form.type"
          :items="typeOptions"
          outlined
          label="Type"
          placeholder="Select Type"
          class="mt-5"
        ></v-select>

        <translatable-input
          v-model="form.title"
          label="Title"
          outlined
          class="mt-5"
        />

        <translatable-editor
          v-model="form.content"
          label="Content"
          outlined
          class="mt-5"
          :eidtor-options="{ uploadUrl: 'news', postUuid: form.post_uuid }"
        />

        <v-select
          v-model="form.status"
          :items="statusOptions"
          outlined
          label="Status"
          placeholder="Select Status"
          class="mt-5"
        ></v-select>

        <v-combobox
          v-model="form.distributor_ids"
          :items="distributorOptions"
          item-value="value"
          item-text="title"
          multiple
          chips
          label="Distributors"
          outlined
          hint="Leave it blank if you want to publish to users in all distributors."
          persistent-hint
        />

        <v-checkbox
          v-model="form.in_app_notification"
          label="Send in-app notification"
          hint="Ensure that the news content is brief and contains text only for in-app notifications."
          persistent-hint
        />

        <p class="mt-5">
          Publish Datetime
        </p>

        <v-row>
          <v-col col="6">
            <v-date-picker v-model="form.date" />
          </v-col>
          <v-col col="6">
            <v-time-picker
              v-model="form.time"
              color="primary"
              ampm-in-title
              :allowed-minutes="[0,15,30,45]"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import store from '@/store'
import { now } from '@core/utils/filter'
import { between, required } from '@core/utils/validation'
import {} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'
import { useDistributor } from '@/composables'

export default {
  components: {
    TranslationLocales,
    TranslatableInput,
    TranslatableEditor,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const editMode = computed(() => props.resource !== null)

    const { distributorOptions } = useDistributor()

    const field = (name, initValue) => {
      if (name === 'distributor_ids') {
        return {
          [name]: props.resource
            ? props.resource[name]?.map(distributorId => {
                const distributor = distributorOptions.value.find(distributor => distributor.value === distributorId)

                return distributor ?? {}
              })
            : [],
        }
      }

      return {
        [name]: props.resource ? props.resource[name] : initValue,
      }
    }

    const statusOptions = computed(() => {
      const statuses = ['scheduled', 'published', 'unpublished', 'draft']

      if (editMode.value) {
        return statuses
      }

      return statuses.filter(status => status !== 'unpublished')
    })

    const typeOptions = ref(['announcement', 'promotion', 'news'])

    const initialForm = {
      ...field('title', useTranslatable('title')),
      ...field('content', useTranslatable('content')),
      ...field('distributor_ids', []),
      ...field('in_app_notification', false),
      ...field('published_at', null),
      ...field('date', null),
      ...field('time', null),
      ...field('post_uuid', uuidv4()),
      ...field('type', 'announcement'),
      ...field('status', 'scheduled'),
    }

    if (initialForm.published_at) {
      initialForm.date = now(initialForm.published_at).format('YYYY-MM-DD')
      initialForm.time = now(initialForm.published_at).format('HH:mm')
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return

      const data = {
        ...form.value,
      }

      data.distributor_ids = data.distributor_ids.map(item => item.value)
      data.published_at = `${now(data.date).format('YYYY-MM-DD').toString()}T${data.time}:00+08:00`

      emit('submit', data)
    }

    return {
      form,
      formElem,
      validate,
      required,
      between,
      editMode,
      distributorOptions,
      typeOptions,
      statusOptions,
    }
  },
}
</script>
