import axios from '@axios'

const getNewsList = params => axios.get('/admin/news', { params })

const storeNews = data => axios.post('/admin/news', data)

const updateNews = (id, data) => axios.put(`/admin/news/${id}`, data)

const destroyNews = id => axios.get(`/admin/news/${id}`)

const unpublishNews = id => axios.post(`/admin/news/${id}/unpublish`)

export { destroyNews, getNewsList, storeNews, unpublishNews, updateNews }
