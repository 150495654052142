var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "height": "400px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "17"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPublishOff) + " ")]), _c('span', [_vm._v("Unpublish")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('v-card', {
    attrs: {
      "title": "Import Doctor List"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v("Title: " + _vm._s(_vm.news.title.en))]), _c('p', [_vm._v("All associated notifications will be deleted. Are you sure you want to unpublish this news?")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error",
      "outlined": "",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" Unpublish ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }